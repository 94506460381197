// export const skinmktFilterConfig = {
//   platformDefaultData: {
//     selected: {
//       val: 'YOUPIN',
//       label: $t('悠悠')
//     },
//     list: [
//       {
//         val: 'all',
//         label: $t('全部')
//       },
//       {
//         val: 'C5',
//         label: $t('C5GAME')
//       },
//       {
//         val: 'YOUPIN',
//         label: $t('悠悠')
//       },
//       {
//         val: 'BUFF',
//         label: $t('BUFF')
//       }
//     ]
//   }
// }

// export const baseKeyList = [
//   {
//     key: 'SellPrice',
//     label: '在售价',
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额' },
//       { key: 'RiseFall', label: '涨跌幅' }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', unit: true, sortAble: true },
//       { key: 'end', label: '截止', unit: true, sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, unit: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
//     ]
//   },
//   {
//     key: 'SellCount',
//     label: '在售数',
//     minDefault: 50,
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额' },
//       { key: 'RiseFall', label: '涨跌幅' }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', sortAble: true },
//       { key: 'end', label: '截止', sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
//     ]
//   },
//   {
//     key: 'BiddingPrice',
//     label: '求购价',
//     hide: true,
//     allHide: true,
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', hide: true },
//       { key: 'RiseFall', label: '涨跌幅', hide: true }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', unit: true, sortAble: true },
//       { key: 'end', label: '截止', unit: true, sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, unit: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
//     ]
//   },
//   {
//     key: 'BiddingCount',
//     label: '求购数',
//     hide: true,
//     allHide: true,
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', hide: true },
//       { key: 'RiseFall', label: '涨跌幅', hide: true }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', sortAble: true },
//       { key: 'end', label: '截止', sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
//     ]
//   },
//   {
//     key: 'TransactionAmount',
//     label: '成交额',
//     hide: true,
//     allHide: true,
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', hide: true },
//       { key: 'RiseFall', label: '涨跌幅', hide: true }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', unit: true, sortAble: true },
//       { key: 'end', label: '截止', unit: true, sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, unit: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
//     ]
//   },
//   {
//     key: 'TransactionCount',
//     label: '成交量',
//     hide: true,
//     allHide: true,
//     apiPrefixList: [
//       { key: 'min', label: '' },
//       { key: 'max', label: '' }
//     ],
//     apiSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', hide: true },
//       { key: 'RiseFall', label: '涨跌幅', hide: true }
//     ],
//     resultPrefixList: [
//       { key: 'start', label: '起始', sortAble: true },
//       { key: 'end', label: '截止', sortAble: true }
//     ],
//     resultSuffixList: [
//       { key: 'RiseFallValue', label: '涨跌额', sortAble: true, valueColor: true },
//       { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true }
//     ]
//   }
// ]
export const selectionFilterConfig = function (i18n) {
  const { t } = i18n
  const $t = t
  return {
    platformDefaultData: {
      selected: {
        val: 'YOUPIN',
        label: $t('悠悠')
      },
      list: [
        {
          val: 'all',
          label: $t('全部')
        },
        {
          val: 'C5',
          label: $t('C5GAME')
        },
        {
          val: 'YOUPIN',
          label: $t('悠悠')
        },
        {
          val: 'BUFF',
          label: $t('BUFF')
        }
      ]
    },
    baseKeyList: [
      {
        key: 'SellPrice',
        label: $t('在售价'),
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额') },
          { key: 'RiseFall', label: $t('涨跌幅') }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), unit: true, sortAble: true },
          { key: 'end', label: $t('截止'), unit: true, sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, unit: true, valueColor: true },
          { key: 'RiseFall', label: $t('涨跌幅'), sortAble: true, valueColor: true, valueSuffix: '%' }
        ]
      },
      {
        key: 'SellCount',
        label: $t('在售数'),
        minDefault: 50,
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额') },
          { key: 'RiseFall', label: $t('涨跌幅') }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), sortAble: true },
          { key: 'end', label: $t('截止'), sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, valueColor: true },
          { key: 'RiseFall', label: $t('涨跌幅'), sortAble: true, valueColor: true, valueSuffix: '%' }
        ]
      },
      {
        key: 'BiddingPrice',
        label: $t('求购价'),
        hide: true,
        allHide: true,
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), hide: true },
          { key: 'RiseFall', label: $t('涨跌幅'), hide: true }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), unit: true, sortAble: true },
          { key: 'end', label: $t('截止'), unit: true, sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, unit: true, valueColor: true },
          { key: 'RiseFall', label: $t('涨跌幅'), sortAble: true, valueColor: true, valueSuffix: '%' }
        ]
      },
      {
        key: 'BiddingCount',
        label: $t('求购数'),
        hide: true,
        allHide: true,
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), hide: true },
          { key: 'RiseFall', label: $t('涨跌幅'), hide: true }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), sortAble: true },
          { key: 'end', label: $t('截止'), sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, valueColor: true },
          { key: 'RiseFall', label: '涨跌幅', sortAble: true, valueColor: true, valueSuffix: '%' }
        ]
      },
      {
        key: 'TransactionAmount',
        label: $t('成交额'),
        hide: true,
        allHide: true,
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), hide: true },
          { key: 'RiseFall', label: $t('涨跌幅'), hide: true }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), unit: true, sortAble: true },
          { key: 'end', label: $t('截止'), unit: true, sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, unit: true, valueColor: true },
          { key: 'RiseFall', label: $t('涨跌幅'), sortAble: true, valueColor: true, valueSuffix: '%' }
        ]
      },
      {
        key: 'TransactionCount',
        label: $t('成交量'),
        hide: true,
        allHide: true,
        apiPrefixList: [
          { key: 'min', label: '' },
          { key: 'max', label: '' }
        ],
        apiSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), hide: true },
          { key: 'RiseFall', label: $t('涨跌幅'), hide: true }
        ],
        resultPrefixList: [
          { key: 'start', label: $t('起始'), sortAble: true },
          { key: 'end', label: $t('截止'), sortAble: true }
        ],
        resultSuffixList: [
          { key: 'RiseFallValue', label: $t('涨跌额'), sortAble: true, valueColor: true },
          { key: 'RiseFall', label: $t('涨跌幅'), sortAble: true, valueColor: true }
        ]
      }
    ]
  }
}
