<template>
  <div class="common-filter-item-container mb-10 flex items-center rounded-[10px] py-5 pl-10 pr-6" :class="`w-${width}`" :style="{ width: `${width}px` }">
    <div class="label text-color-lightgray" :class="[`${labelWidth ? `w-${labelWidth}` : 'w-66'}`]">{{ label }}</div>
    <div v-if="type === 'single'" class="single flex-grow">
      <ClientOnly>
        <el-dropdown ref="singleDropdown" class="block h-full w-full" style="height: 26px; outline: none" :popper-class="`common-filter-item-single-menu w-320`" trigger="click">
          <div class="single-content relatvie flex h-full flex-grow items-center">
            <div class="pl-10">{{ filtedCondition.singleData.label }}</div>
            <i class="arrow"></i>
          </div>
          <template #dropdown>
            <div class="single-dropdown-menu">
              <div v-for="item in defaultData.list" :key="item.val" class="single-dropdown-item cursor-pointer p-10 text-14" @click="singleSelect(item)">
                {{ item.label }}
              </div>
            </div>
          </template>
        </el-dropdown>
      </ClientOnly>
    </div>
    <div v-if="type === 'range'" class="flex flex-grow items-center justify-between">
      <ClientOnly>
        <!-- <el-dropdown
          ref="rangeTypeDropdown"
          class="border-right block h-21 w-80 pr-5"
          style="height: 21px; outline: none"
          :popper-class="`common-filter-item-single-menu w-110`"
          trigger="click"
        >
          <div class="single-content relatvie flex h-full w-80 items-center">
            <div class="pl-10">{{ relOp.rangeTypeData.selected.label }}</div>

            <i class="arrow" style="right: 5px"></i>
          </div>
          <template #dropdown>
            <div class="single-dropdown-menu">
              <div
                v-for="rangeType in relOp.rangeTypeData.list"
                :key="rangeType.val"
                class="single-dropdown-item cursor-pointer p-10 text-14"
                @click="rangeTypeSelect(rangeType)"
              >
                {{ rangeType.label }}
              </div>
            </div>
          </template>
        </el-dropdown> -->
      </ClientOnly>
      <div class="input-group-container flex-grow pl-10" :class="defaultData.dayType ? 'border-right' : ''">
        <div class="flex items-center">
          <span>{{ inputPrefix }}</span>
          <el-input v-model="filtedCondition.rangeData.val.minVal" style="width: 60px" class="h-21 text-black" :placeholder="$t('最小值')" @change="inputChange"></el-input>
          <div class="input-divider mr-20"></div>
          <el-input v-model="filtedCondition.rangeData.val.maxVal" style="width: 60px" class="h-21" :placeholder="$t('最大值')" @change="inputChange"></el-input>
          <span class="1">{{ inputSuffix }}</span>
        </div>
        <!-- <div v-if="relOp.rangeTypeData.selected.val === 'greater'" class="flex items-center">
          <span>{{ inputPrefix }}</span>
          <el-input
            v-model="filtedCondition.rangeData.val.minVal"
            :style="defaultData.dayType ? { width: '180px' } : { width: '190px' }"
            class="h-21"
            :placeholder="$t('最小值')"
            @change="inputChange"
          ></el-input>
          <span>{{ inputSuffix }}</span>
        </div>
        <div v-if="relOp.rangeTypeData.selected.val === 'less'" class="flex items-center">
          <span>{{ inputPrefix }}</span>
          <el-input
            v-model="filtedCondition.rangeData.val.maxVal"
            :style="defaultData.dayType ? { width: '180px' } : { width: '190px' }"
            class="h-21"
            :placeholder="$t('最大值')"
            @change="inputChange"
          ></el-input>
          <span>{{ inputSuffix }}</span>
        </div> -->
      </div>
      <ClientOnly v-if="defaultData.dayType">
        <el-dropdown ref="dayTypeDropdown" class="block h-21 w-50" style="height: 21px; outline: none" :popper-class="`common-filter-item-single-menu w-110`" trigger="click">
          <div class="single-content relatvie flex h-full w-50 items-center">
            <div class="pl-10">{{ dayTypeData.selected.label }}</div>

            <i class="arrow" style="right: 0px"></i>
          </div>
          <template #dropdown>
            <div class="single-dropdown-menu">
              <div v-for="dayType in dayTypeData.list" :key="dayType.val" class="single-dropdown-item cursor-pointer p-10 text-14" @click="dayTypeSelect(dayType)">
                {{ dayType.label }}
              </div>
            </div>
          </template>
        </el-dropdown>
      </ClientOnly>
    </div>
    <div v-if="type === 'include'" class="flex flex-grow items-center">
      <ClientOnly>
        <!-- <el-dropdown
          ref="includeTypeDropdown"
          class="border-right block h-21 w-80 pr-5"
          style="height: 21px; outline: none"
          :popper-class="`common-filter-item-single-menu w-110`"
          trigger="click"
        >
          <div class="single-content relatvie flex h-full w-80 items-center">
            <div class="pl-10">{{ relOp.includeTypeData.selected.label }}</div>

            <i class="arrow" style="right: 5px"></i>
          </div>
          <template #dropdown>
            <div class="single-dropdown-menu">
              <div
                v-for="includeType in relOp.includeTypeData.list"
                :key="includeType.val"
                class="single-dropdown-item cursor-pointer p-10 text-14"
                @click="includeTypeSelect(includeType)"
              >
                {{ includeType.label }}
              </div>
            </div>
          </template>
        </el-dropdown> -->
      </ClientOnly>
      <div class="category-dropdown">
        <ClientOnly>
          <filterlist ref="filterListRef" :data="filterData" :width="'310px'" height="36px" class="w-310" type="multiple" @update-data="categoryUpdate"></filterlist>
        </ClientOnly>
      </div>
    </div>
    <div v-if="type === 'timeRange'" class="picker w-300">
      <el-date-picker
        v-model="filtedCondition.timeRangeData"
        type="datetimerange"
        :shortcuts="shortcuts"
        :range-separator="$t('至')"
        :editable="false"
        :format="'YYYY-MM-DD HH:00:00'"
        :start-placeholder="$t('开始时间')"
        :end-placeholder="$t('结束时间')"
        :default-value="['Tue Jun 11 2024 00:00:00 GMT+0800 (中国标准时间)', 'Tue Jun 12 2024 00:00:00 GMT+0800 (中国标准时间)']"
        :disabled-date="dateDisabled"
        @change="updateTimeRange"
      />
    </div>
  </div>
</template>

<script setup>
import filterlist from './filterlist.vue'
import dayjs from 'dayjs'
const { t } = useI18n()
const $t = t
const filterListRef = ref(null)
const props = defineProps({
  type: {
    type: String,
    default: 'single'
  },
  filterKey: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  filterData: {
    type: Object,
    default: () => {}
  },
  defaultData: {
    type: Object,
    default: () => {}
  },
  inputSuffix: {
    type: String,
    default: ''
  },
  inputPrefix: {
    type: String,
    default: ''
  },
  width: {
    type: [Number, String],
    default: 400
  },
  labelWidth: {
    type: Number,
    default: 66
  },
  resetSwitch: {
    type: Object,
    default: () => {}
  },
  forceData: {
    type: Object,
    default: () => {}
  }
})

const searchKey = ref('')

watch(props.resetSwitch, val => {
  // console.log('watch', val)
  if (val.val) {
    console.log('watch')
    reset()
  }
})

onMounted(() => {})
const singleDropdown = ref(null)
const rangeTypeDropdown = ref(null)
const dayTypeDropdown = ref(null)
const includeTypeDropdown = ref(null)
let filtedCondition = reactive({
  singleData: {},
  rangeData: {},
  includeData: {},
  timeRangeData: props.type === 'timeRange' ? [new Date(props.defaultData[0] * 1000), new Date(props.defaultData[1] * 1000)] : []
})
const relOp = reactive({
  singleTypeData: {},
  rangeTypeData: {},
  includeTypeData: {}
})
relOp.rangeTypeData = reactive({
  selected: {
    label: $t('介于'),
    val: 'between'
  },
  list: [
    {
      label: $t('介于'),
      val: 'between'
    },
    {
      label: $t('大于'),
      val: 'greater'
    },
    {
      label: $t('小于'),
      val: 'less'
    }
  ]
})
const dayTypeData = reactive({
  selected: {
    label: $t('7天'),
    val: 2
  },
  list: [
    {
      label: $t('1天'),
      val: 1
    },
    {
      label: $t('7天'),
      val: 2
    },
    {
      label: $t('15天'),
      val: 3
    },
    {
      label: $t('30天'),
      val: 4
    }
  ]
})

relOp.includeTypeData = reactive({
  selected: {
    label: $t('包含'),
    val: 'include'
  },
  list: [
    {
      label: $t('包含'),
      val: 'include'
    },
    {
      label: $t('排除'),
      val: 'declude'
    }
  ]
})
relOp.timeRangeTypeData = null

let timeRangeData = ref([null, null])

const shortcuts = [
  {
    text: $t('最近一周'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setDate(start.getDate() - 7)
      return [start, end]
    }
  },
  {
    text: $t('最近一月'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      return [start, end]
    }
  },
  {
    text: $t('最近三月'),
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      return [start, end]
    }
  }
]

if (props.type === 'single') {
  filtedCondition.singleData = props.defaultData.selected
}

if (props.type === 'range') {
  if ((props.defaultData.val.minVal && props.defaultData.val.maxVal) || (!props.defaultData.val.minVal && !props.defaultData.val.maxVal)) {
    relOp.rangeTypeData.selected = relOp.rangeTypeData.list[0]
  } else if (props.defaultData.val.minVal && !props.defaultData.val.maxVal) {
    relOp.rangeTypeData.selected = relOp.rangeTypeData.list[1]
  } else {
    relOp.rangeTypeData.selected = relOp.rangeTypeData.list[2]
  }
  filtedCondition.rangeData = reactive(JSON.parse(JSON.stringify(props.defaultData)))
  if (props.defaultData.dayType) {
    dayTypeData.selected = dayTypeData.list.find(item => item.val === props.defaultData.dayType)
  }
}
if (props.type === 'inclued') {
  filtedCondition.includeData = reactive({})
}
const emit = defineEmits(['updateData'])
function singleSelect(item) {
  filtedCondition.singleData = item
  emitSelfData()
  singleDropdown.value.handleClose()
}
function rangeTypeSelect(item) {
  relOp.rangeTypeData.selected = item
  emitSelfData()
  rangeTypeDropdown.value.handleClose()
}

function dayTypeSelect(daytype) {
  dayTypeData.selected = daytype
  emitSelfData()
  dayTypeDropdown.value.handleClose()
}

function includeTypeSelect(includeType) {
  relOp.includeTypeData.selected = includeType
  emitSelfData()
  includeTypeDropdown.value.handleClose()
}
function categoryUpdate(data, searchkey) {
  filtedCondition.includeData = data
  searchKey.value = searchkey
  emitSelfData()
}
function inputChange() {
  emitSelfData()
}

function updateTimeRange(val) {
  emitSelfData()
}

function dateDisabled(date) {
  const now = new Date() // 获取当前日期
  const limitDate = new Date(2024, 4, 29) // 设置限制日期为 2024 年 5 月 29 日
  let res = false
  // 如果 date 早于 2024 年 5 月 29 日或者晚于今天的日期
  if (date < limitDate || date > now) {
    res = true // 返回 false
  } else {
    res = false // 否则返回 true
  }
  return res
}

function emitSelfData() {
  console.log('emitSelfData', filtedCondition[`${props.type}Data`], relOp[`${props.type}TypeData`])
  emit('updateData', {
    type: props.type,
    filterKey: props.filterKey,
    val: filtedCondition[`${props.type}Data`],
    relOp: relOp[`${props.type}TypeData`],
    dayType: props?.defaultData?.dayType ? dayTypeData.selected.val : null,
    searchKey: searchKey.value
  })
}

function reset(forceData) {
  console.log('reset !!!!', props.type)
  // filtedCondition = reactive({
  //   singleData: {},
  //   rangeData: {},
  //   includeData: {}
  // })
  filtedCondition[`${props.type}Data`] = {}
  if (props.type === 'single') {
    filtedCondition.singleData = props.defaultData.selected
  }
  if (props.type === 'range') {
    filtedCondition.rangeData.val = forceData || {
      minVal: props.defaultData.val.minVal,
      maxVal: props.defaultData.val.maxVal
    }
    // filtedCondition.rangeData.val.maxVal = props.defaultData.val.maxVal
  }
  if (props.type === 'include') {
    filtedCondition.includeData = reactive({})
    filterListRef.value.resetData()
  }
  if (forceData?.dayType) {
    dayTypeData.selected = dayTypeData.list.find(item => item.val === forceData.dayType)
  }

  emitSelfData()
}
defineExpose({ reset, filterKey: props.filterKey })
</script>

<style lang="scss" scoped>
.common-filter-item-container {
  height: 36px;
  border: 1px solid #dddfe7;
  background: #ffffff;
  .label {
    border-right: 1px solid #dddfe7;
  }
  .single-content {
    outline: none !important;
  }

  .arrow {
    width: 0;
    height: 0;
    border-bottom: 12px solid #dddfe7;
    border-left: 12px solid transparent;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .border-right {
    border-right: 1px solid #dddfe7;
  }
  .input-group-container {
    .input-divider {
      width: 20px;
      height: 1px;
      border: 0.5px dashed #dddfe7;
    }
    :deep(.el-input__wrapper) {
      border: none;
      box-shadow: none;
      padding: 0;
      .el-input__inner {
        // color: #979cae;
      }
    }
  }
  .category-dropdown {
    width: 240px;
    :deep(.el-tooltip__trigger) {
      border: none !important;
      background-color: transparent;
      .arrow {
        right: -5px;
      }
    }
  }
  .picker {
    :deep(.el-range-editor) {
      border: none;
      box-shadow: none;
    }
  }
}
</style>
<style lang="scss">
.common-filter-item-single-menu {
  margin-top: -5px;
  margin-bottom: -5px;

  .single-dropdown-menu {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dddfe7;
    width: 100%;
    .single-dropdown-item {
      &:hover {
        background: #dddfe7;
        color: #000;
      }
    }
  }
  .el-popper__arrow {
    display: none;
  }
}
.el-picker-panel {
  .el-time-spinner {
    .el-scrollbar {
      &:first-child {
        width: 100%;
      }
      &:last-child {
        display: none;
      }
    }
  }
}
</style>
